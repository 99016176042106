import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Blur } from "transitions-kit";
import { useSearchParams } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "../header/NavBar";
import SecondaryNavBar from "../header/SecondaryNavBar";

import { SORT_ORDER_ASCENDING, SORT_ORDER_DESCENDING } from "../../utils/sort";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { MdOutlineStar, MdOutlineStarBorder } from "react-icons/md";
import { NoProductsDisplay } from "../NoProductsDisplay";
import RatingStarIcon from "../../icons/RatingStarIcon";
import {
  selectSearchProducts,
  selectSearchTotalResults,
} from "../../reducers/products/listingSelectors";
import { searchProductsByQuery } from "../../reducers/products/actions";
import Pagination from "./Pagination";
import { Footer } from "../header/Footer";
import "./productGroupPage.scss";
import "./SearchProductsPage.scss";

export const SearchProductsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sortingOrder, setSortingOrder] = useState(SORT_ORDER_ASCENDING);
  const [discountOption, setDiscountOption] = useState(10);
  const [ratingOption, setRatingOption] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // const [priceRange, setPriceRange] = useState({ min: 0, max: 200 });
  const [searchParams] = useSearchParams();
  const query = useMemo(() => {
    return searchParams.get("q") || [];
  }, [searchParams]);

  const handleSortOrder = () => {
    setSortingOrder((prev) =>
      prev === SORT_ORDER_DESCENDING
        ? SORT_ORDER_ASCENDING
        : SORT_ORDER_DESCENDING,
    );
  };

  const createRatingStars = (fullStars) => {
    return (
      <div className="star-rating">
        {[...Array(fullStars)].map((_, idx) => (
          <MdOutlineStar key={idx} size={20} />
        ))}
        {[...Array(5 - fullStars)].map((_, idx) => (
          <MdOutlineStarBorder key={idx} size={20} />
        ))}
        &nbsp;and up
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(
      searchProductsByQuery({ query, pageNum: currentPage - 1, pageSize: 20 }),
    );
  }, [dispatch, currentPage, query]);

  const products = useSelector(selectSearchProducts);
  const totalResults = useSelector(selectSearchTotalResults);
  if (products.length === 0) {
    return <NoProductsDisplay />;
  }

  return (
    <div className="App">
      <div className="navbar-container">
        <Navbar />
        <SecondaryNavBar />
      </div>
      <div className="product-group-container">
        <div className="product-group-row product-group-title">
          <div className="product-group-title-directory">
            <u>Home</u>&nbsp;/&nbsp;<u>Shop All</u>
          </div>
          <div
            className="product-group-title-sorting"
            onClick={() => handleSortOrder()}
          >
            <span>
              {sortingOrder === SORT_ORDER_DESCENDING ? (
                <>
                  <b>{t("products_sort_price_1")}</b>&nbsp;&nbsp; | &nbsp;
                  {t("products_sort_price_desc")}&nbsp;&nbsp;
                  <IoIosArrowDown />
                </>
              ) : (
                <>
                  <b>{t("products_sort_price_1")}</b>&nbsp;&nbsp; | &nbsp;
                  {t("products_sort_price_asc")}&nbsp;&nbsp;
                  <IoIosArrowUp />
                </>
              )}
            </span>
          </div>
        </div>
        <div className="product-group-row product-group-body">
          <div className="product-group-body-filter">
            {/* <div className="product-group-body-filter-apply group-filter">
              <div className="product-group-body-filter-apply-text">
                Clear All
              </div>
              <div className="product-group-body-filter-apply-button">
                Apply
              </div>
            </div> */}
            {/* <div className="product-group-body-filter-price-range group-filter">
              <div className="product-group-body-filter-price-range-title">
                <div className="product-group-body-filter-price-range-title-left">
                  Price Range
                </div>
                <div className="product-group-body-filter-price-range-title-right">
                  ${priceRange.min} - ${priceRange.max}
                </div>
              </div>
              <div className="product-group-body-filter-price-range-slider">
                <MultiRangeSlider
                  min={priceRange.min}
                  max={200}
                  rangeMax={200}
                  onRangeChange={(range) => setPriceRange(range)}
                />
              </div>
            </div> */}
            <div className="product-group-body-filter-discount group-filter">
              <div className="product-group-body-filter-text">
                <span>{t("products_filter_discount")}</span>
              </div>
              {[60, 30, 10].map((value, idx) => (
                <div
                  className="product-group-body-filter-discount-option product-group-body-filter-option"
                  key={idx}
                >
                  <input
                    type="radio"
                    value={value}
                    checked={discountOption === value}
                    onChange={(event) =>
                      setDiscountOption(parseInt(event.target.value))
                    }
                  />
                  <span>{value}% and up</span>
                </div>
              ))}
            </div>
            <div className="product-group-body-filter-rating group-filter">
              <div className="product-group-body-filter-text">
                <span>{t("products_filter_rating")}</span>
              </div>
              {[4, 3, 2, 1].map((rating, idx) => (
                <div
                  className="product-group-body-filter-rating-option product-group-body-filter-option"
                  key={idx}
                >
                  <input
                    type="radio"
                    value={rating}
                    checked={ratingOption === rating}
                    onChange={(event) =>
                      setRatingOption(parseInt(event.target.value))
                    }
                  />
                  <div className="product-group-body-filter-option-text">
                    {createRatingStars(rating)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="product-group-body-list">
            {products.map((product, idx) => (
              <Link
                key={idx}
                className="product-group-body-grid-item-search category-link"
                to={`/product/${product.sku}`}
                target="_blank"
              >
                <div className="product-group-body-grid-item-image-container">
                  <AsyncImage
                    src={product.imageUrl}
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: 0,
                      backgroundColor: "#f5f5f5",
                    }}
                    Transition={Blur}
                    loader={<div style={{ background: "#888" }} />}
                  />
                </div>
                <div className="product-group-item-discount">
                  <span>{product.discount}% OFF</span>
                </div>
                <div className="product-group-item-product-name">
                  <span>{product.name}</span>
                </div>
                <div className="product-group-item-price-and-rating">
                  <div className="product-group-item-product-price">
                    ${product.price}
                  </div>
                  <span className="product-group-item-list-price">
                    ${product.listPrice}
                  </span>
                  <div className="product-group-item-rating">
                    <RatingStarIcon
                      width={20}
                      height={20}
                      className={"product-group-item-rating-icon"}
                    />
                    <span className="product-group-item-rating-number">
                      {product.rating}
                    </span>
                    <span className="product-group-item-rating-count">
                      ({product.ratingCount})
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="product-group-row product-group-pagination">
          <Pagination
            className="product-group-pagination-bar"
            currentPage={currentPage}
            totalCount={totalResults > 9980 ? 9980 : totalResults}
            pageSize={20}
            siblingCount={0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};
