import React from "react";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import bannerImage1 from "../../images/banner1-01-01.png";
import trustImage from "../../images/trust-words.png";
import Navbar from "../header/NavBar";
import SecondaryNavBar from "../header/SecondaryNavBar";
import TrendingProducts from "./TrendingProducts";

import "./mainPage.scss";
import ShopByCategories from "./ShopByCategories";
import { Footer } from "../header/Footer";

export const MainPage = () => {
  const { t } = useTranslation();

  return (
    <div className="App">
      <div className="navbar-container">
        <Navbar />
        <SecondaryNavBar />
      </div>
      <div className="main-page-container">
        <div className="row main-page-banner">
          <AsyncImage
            src={bannerImage1}
            style={{ width: "100%", height: "553px", borderRadius: 0 }}
            Transition={Blur}
            loader={<div style={{ background: "#888" }} />}
          />
          <Link
            to="/products"
            className="main-page-banner-shop-now-button"
            style={{ textDecoration: "none" }}
          >
            <span>{t("main_page_shop_now")}</span>
          </Link>
        </div>
        <div className="row row-2">
          <TrendingProducts />
        </div>
        <div className="row row-3">
          <Link
            to="/products"
            className="main-page-view-all-products"
            style={{ textDecoration: "none" }}
          >
            <span>{t("main_page_view_all_products")}</span>
          </Link>
        </div>
        <div className="row row-4">
          <div className="main-page-shop-by-category-title">
            <span>{t("menu_bar_shop_by_category")}</span>
          </div>
        </div>
        <div className="row row-5">
          <ShopByCategories />
        </div>
        <div className="row row-6">
          <AsyncImage
            src={trustImage}
            style={{ width: "100%", height: "250px", borderRadius: 0 }}
            Transition={Blur}
            loader={<div style={{ background: "#888" }} />}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};
