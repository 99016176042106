import React from "react";
import Navbar from "./header/NavBar";
import { Footer } from "./header/Footer";
import "../App.scss";
import "./aboutpage.scss";

export const AboutPage = () => (
  <div className="App">
    <Navbar />
    <div className="about-page-container">
      <h1>About SavvyDeal</h1>
      <div className="about-content">
        SavvyDeal is an online marketplace that connects consumers with millions
        of discounted items from sellers, manufacturers and brands around the
        world with the mission to empower them to live their best lives.
        SavvyDeal is committed to offering the most affordable quality products
        to enable consumers and sellers to fulfill their dreams in an inclusive
        environment. SavvyDeal was founded in Sunnyvale, California in 2023.
      </div>
    </div>
    <Footer />
  </div>
);
