import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectChatMessagesAndTyping } from "../reducers/chats/createSelectors";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  Avatar,
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "./chatBox.scss";
import {
  addNewMessage,
  sendMessagesToChatGPT,
} from "../reducers/chats/actions";
import chatbotAvatar from "../images/chatbot-avatar.png";
import userAvatar from "../images/user.png";
import { CHATGPT, OUTGOING, USER } from "../reducers/chats/constants";

const ChatBox = () => {
  const { messages, typing } = useSelector(selectChatMessagesAndTyping);
  const dispatch = useDispatch();

  const handleSendMessage = (message) => {
    const newMessage = {
      message,
      sender: USER,
      direction: OUTGOING,
    };
    dispatch(addNewMessage(newMessage));
    dispatch(sendMessagesToChatGPT());
  };

  return (
    <div className="chatbox-container">
      <MainContainer>
        <ChatContainer>
          <MessageList
            typingIndicator={
              typing ? (
                <TypingIndicator content="Savvy Assistant is typing" />
              ) : null
            }
          >
            {messages.map((message, i) => (
              <Message key={i} model={message}>
                {message.sender === CHATGPT ? (
                  <Avatar src={chatbotAvatar} name="assistant" size="sm" />
                ) : (
                  <Avatar src={userAvatar} name="You" size="sm" />
                )}
              </Message>
            ))}
          </MessageList>
          <MessageInput
            placeholder="Type your message here"
            onSend={handleSendMessage}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default ChatBox;
