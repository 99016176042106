import React from "react";
import Navbar from "../header/NavBar";
import SecondaryNavBar from "../header/SecondaryNavBar";
import ProductDetailPage from "../redesign/ProductDetailPage";
import DirectoryNavBar from "../header/DirectoryNavBar";
import { Footer } from "../header/Footer";

export const ProductPage = () => (
  <div className="App">
    <div className="navbar-container">
      <Navbar />
      <SecondaryNavBar />
      <DirectoryNavBar />
    </div>
    <ProductDetailPage />
    <Footer />
  </div>
);
