import React from "react";
import Navbar from "./header/NavBar";
import { Footer } from "./header/Footer";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "./contact.scss";
import "../App.scss";
import { useDispatch } from "react-redux";
import { userSendSupportInquery } from "../reducers/users/actions";
import MediaQuery from "react-responsive";
import { useTranslation } from "react-i18next";
import SecondaryNavBar from "./header/SecondaryNavBar";

const ContactUsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notify = () =>
    toast.success("Inquery submit success!", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
    });

  const handleFormSubmit = (data) => {
    dispatch(userSendSupportInquery({ data }));
    notify();
  };

  return (
    <div className="App">
      <Navbar />
      <SecondaryNavBar />
      <MediaQuery minWidth={800}>
        {(matches) =>
          matches ? (
            <div className="contact-us-container">
              <div className="contact-form">
                <span className="contact-title">{t("contact_us_title")}</span>
                <span>{t("contact_us_content")}</span>
                <input
                  className="contact-us-form-field"
                  {...register("name", {
                    required: {
                      value: true,
                      message: t("contact_us_form_name_required_message"),
                    },
                  })}
                  placeholder={t("contact_us_form_name_placeholder")}
                />
                {errors.name ? (
                  <div className="error-message">{errors.name.message}</div>
                ) : null}
                <input
                  className="contact-us-form-field"
                  {...register("email", {
                    required: {
                      value: true,
                      message: t("contact_us_form_email_required_message"),
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t("contact_us_form_email_not_valid"),
                    },
                  })}
                  placeholder={t("contact_us_form_email_placeholder")}
                />
                {errors.email ? (
                  <div className="error-message">
                    {errors.email.message || errors.email.pattern}
                  </div>
                ) : null}
                <input
                  type="tel"
                  className="contact-us-form-field"
                  {...register("phone", {
                    required: {
                      value: true,
                      message: t("contact_us_form_phone_required_message"),
                    },
                    pattern: {
                      value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
                      message: t("contact_us_form_phone_not_valid"),
                    },
                  })}
                  placeholder={t("contact_us_form_phone_placeholder")}
                />
                {errors.phone ? (
                  <div className="error-message">
                    {errors.phone.message || errors.phone.pattern}
                  </div>
                ) : null}
                <input
                  className="contact-us-form-field"
                  {...register("subject", {
                    required: {
                      value: true,
                      message: t("contact_us_form_subject_required_message"),
                    },
                  })}
                  placeholder={t("contact_us_form_subject_placeholder")}
                />
                {errors.subject ? (
                  <div className="error-message">{errors.subject.message}</div>
                ) : null}
                <textarea
                  {...register("content", {
                    required: {
                      value: true,
                      message: t("contact_us_form_issue_required_message"),
                    },
                    minLength: 1,
                  })}
                  className="desktop-form-textarea"
                  placeholder={t("contact_us_form_content_placeholder")}
                  rows={6}
                />
                {errors.content ? (
                  <div className="error-message">{errors.content.message}</div>
                ) : null}
                <button
                  className="contact-form-submit"
                  onClick={handleSubmit(handleFormSubmit)}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <div className="mobile-contact-us-container">
              <div className="contact-form">
                <span className="mobile-contact-title">
                  {t("contact_us_title")}
                </span>
                <span className="mobile-contact-content">
                  {t("contact_us_content")}
                </span>
                <input
                  className="mobile-contact-us-form-field"
                  {...register("name", {
                    required: {
                      value: true,
                      message: t("contact_us_form_name_required_message"),
                    },
                  })}
                  placeholder={t("contact_us_form_name_placeholder")}
                />
                {errors.name ? (
                  <div className="mobile-error-message">
                    {errors.name.message}
                  </div>
                ) : null}
                <input
                  className="mobile-contact-us-form-field"
                  {...register("email", {
                    required: {
                      value: true,
                      message: t("contact_us_form_email_required_message"),
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t("contact_us_form_email_not_valid"),
                    },
                  })}
                  placeholder={t("contact_us_form_email_placeholder")}
                />
                {errors.email ? (
                  <div className="mobile-error-message">
                    {errors.email.message || errors.email.pattern}
                  </div>
                ) : null}
                <input
                  type="tel"
                  className="mobile-contact-us-form-field"
                  {...register("phone", {
                    required: {
                      value: true,
                      message: t("contact_us_form_phone_required_message"),
                    },
                    pattern: {
                      value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
                      message: t("contact_us_form_phone_not_valid"),
                    },
                  })}
                  placeholder={t("contact_us_form_phone_placeholder")}
                />
                {errors.phone ? (
                  <div className="mobile-error-message">
                    {errors.phone.message || errors.phone.pattern}
                  </div>
                ) : null}
                <input
                  className="mobile-contact-us-form-field"
                  {...register("subject", {
                    required: {
                      value: true,
                      message: t("contact_us_form_subject_required_message"),
                    },
                  })}
                  placeholder={t("contact_us_form_subject_placeholder")}
                />
                {errors.subject ? (
                  <div className="mobile-error-message">
                    {errors.subject.message}
                  </div>
                ) : null}
                <textarea
                  {...register("content", {
                    required: {
                      value: true,
                      message: t("contact_us_form_issue_required_message"),
                    },
                    minLength: 1,
                  })}
                  className="mobile-form-textarea"
                  placeholder={t("contact_us_form_content_placeholder")}
                  rows={6}
                />
                {errors.content ? (
                  <div className="mobile-error-message">
                    {errors.content.message}
                  </div>
                ) : null}
                <button
                  className="mobile-contact-form-submit"
                  onClick={handleSubmit(handleFormSubmit)}
                >
                  {t("common_submit")}
                </button>
              </div>
            </div>
          )
        }
      </MediaQuery>

      <ToastContainer />
      <Footer />
    </div>
  );
};

export default ContactUsPage;
