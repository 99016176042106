import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./trendingProducts.scss";
import Pagination from "./Pagination";
import { Blur } from "transitions-kit";
import { Link } from "react-router-dom";
import { getTrendingProducts } from "../../reducers/products/actions";
import { useTranslation } from "react-i18next";
import { selectTrendingProducts } from "../../reducers/products/listingSelectors";
import { AsyncImage } from "loadable-image";
import RatingStarIcon from "../../icons/RatingStarIcon";
import { toClassNames } from "../../utils/strings";
import { getHotAndLimitedProducts } from "../../utils/products";

const TrendingProducts = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const locale = i18n.language;

  useEffect(() => {
    dispatch(
      getTrendingProducts({ pageNum: currentPage - 1, pageSize: 10, locale }),
    );
  }, [dispatch, currentPage, locale]);

  const products = useSelector(selectTrendingProducts);
  const { hotProductSkus, limitedProductSkus } =
    getHotAndLimitedProducts(products);

  return (
    <div className="trending-product-container">
      <div className="trending-product-row trending-product-row-title">
        <div className="trending-product-row-products-title">
          <span>{t("main_page_trending_products_title")}</span>
        </div>
        <div className="trending-product-row-products-pagination">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={100}
            pageSize={10}
            siblingCount={0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <div className="trending-product-row trending-product-row-product-grid">
        {products.map((product, index) => (
          <div className="product-box" key={index}>
            <div className="product-box-image">
              <Link to={`/product/${product.sku}`} target="_blank">
                <AsyncImage
                  src={product.imageUrl}
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: 0,
                    backgroundColor: "#f5f5f5",
                    postion: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                  Transition={Blur}
                  loader={<div style={{ background: "#888" }} />}
                />
              </Link>
            </div>
            <div className="product-box-discount">{product.discount}% OFF</div>
            <div
              className={toClassNames([
                hotProductSkus.includes(product.sku)
                  ? "product-box-hot"
                  : limitedProductSkus.includes(product.sku)
                  ? "product-box-limited"
                  : "product-box-label-hidden",
              ])}
            >
              {hotProductSkus.includes(product.sku)
                ? "HOT!"
                : limitedProductSkus.includes(product.sku)
                ? "LIMITED"
                : ""}
            </div>
            <div className="product-box-product-name">
              <span>{product.name}</span>
            </div>
            <div className="product-box-product-price-and-rating">
              <div className="product-box-product-price-price">
                ${product.price.toFixed(2)}
              </div>
              <span className="product-box-product-price-list-price">
                ${product.listPrice.toFixed(2)}
              </span>
              <div className="product-box-product-price-rating">
                <RatingStarIcon
                  width={20}
                  height={20}
                  className={"product-box-product-rating-icon"}
                />
                <span>{product.rating}</span>
                <span className="product-rating-count">
                  ({product.ratingCount})
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingProducts;
