import React, { Fragment, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import {
  searchProductsByQuery,
  setSearchQuery,
} from "../../reducers/products/actions";
import {
  selectSearchProductParams,
  selectSearchQuery,
} from "../../reducers/products/listingSelectors";
import { useTranslation } from "react-i18next";
import "./searchBar.scss";

const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useSelector(selectSearchQuery);
  const { pageNum } = useSelector(selectSearchProductParams);
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.get("q");

  useEffect(() => {
    if (queryParams !== null) {
      dispatch(setSearchQuery(queryParams));
    }
  }, [dispatch, queryParams, navigate]);

  const handleSetQuery = (event) => {
    const { value: query } = event.target;
    dispatch(setSearchQuery(query));
  };

  const handleOnKeyPress = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    handleSearchProducts(event);
  };

  const handleSearchProducts = (event) => {
    event.stopPropagation();
    if (query.length === 0) {
      return;
    }
    dispatch(searchProductsByQuery({ query, navigate, pageNum }));
  };
  return (
    <Fragment>
      <input
        type="text"
        className="secondary-navbar-search-bar"
        placeholder={t("menu_bar_search_default")}
        onChange={handleSetQuery}
        onKeyPress={handleOnKeyPress}
      />
      <FaSearch className="secondary-navbar-search-icon" size={16} />
    </Fragment>
  );
};

export default SearchBar;
