import axios from "axios";

const host =
  process.env.REACT_APP_STAGE === "dev"
    ? "http://localhost:7080"
    : "https://app.kupon.ai";

const OPEN_AI_API_KEY = "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";

axios.defaults.baseURL = `${host}/api/v1`;
const mainInstance = axios.create();

const getContentType = (httpMethod) =>
  httpMethod === "patch"
    ? "application/json-patch+json;charset=UTF-8"
    : "application/json;charset=UTF-8";

mainInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": getContentType(config.method),
      "Access-Control-Allow-Origin": "*",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const checkEmailExists = (email) => {
  return mainInstance.post("/users/checkEmail", { email });
};

export const loginUser = ({ data }) => {
  return mainInstance.post("/users/login", data);
};

export const addUser = ({ data }) => {
  return mainInstance.post("/users/add", data);
};

export const searchProducts = ({ query = "*", pageNum = 0, pageSize = 20 }) => {
  return mainInstance.get(
    `/products/search?${queryStringify({ query, pageNum, pageSize })}`,
  );
};

export const getProductBySku = ({ sku, locale = "en-US" }) => {
  return mainInstance.get(`/products/product/${sku}?locale=${locale}`);
};

export const getProductByCategoryWithPageSize = ({
  category = "",
  pageNum = 0,
  pageSize = 20,
  locale = "en-US",
}) => {
  return mainInstance.get(
    `/products?category=${category}&pageNum=${pageNum}&pageSize=${pageSize}&locale=${locale}`,
  );
};

export const getProductsByCategoriesAndFilterAndPage = ({
  categories = [],
  pageNum = 0,
  pageSize = 20,
  locale = "en-US",
  minPrice = 0,
  maxPrice = 800,
  minDiscount = 10,
  minRating = 1,
  sortOrder = null,
}) => {
  return mainInstance.get(
    `/products?${queryStringify({
      categories,
      pageNum,
      pageSize,
      locale,
      minPrice,
      maxPrice,
      minDiscount,
      minRating,
      sortOrder,
    })}`,
  );
};

export const getRecommendProductsByCategory = ({
  category = "",
  sku = "",
  pageSize = 3,
  locale = "en-US",
}) => {
  return mainInstance.get(
    `/products/recommend?${queryStringify({
      category,
      sku,
      pageSize,
      locale,
    })}`,
  );
};

export const getTrendingProducts = ({
  pageNum = 0,
  pageSize = 20,
  locale = "en-US",
}) => {
  return mainInstance.get(
    `/products/trending?${queryStringify({ pageNum, pageSize, locale })}`,
  );
};

export const addUserTracking = ({ userId, sku, category, type }) => {
  return mainInstance.get(
    `/usertracking?${queryStringify({ userId, sku, category, type })}`,
  );
};

export const postSendMessageRequest = ({ data }) => {
  return mainInstance.post("/email", data);
};

export const postMessagesToChatGPT = ({ messages }) => {
  return axios.post(
    "https://api.openai.com/v1/chat/completions",
    { model: "gpt-3.5-turbo", messages },
    {
      headers: {
        Authorization: `Bearer ${OPEN_AI_API_KEY}`,
        ContentType: "application/json",
      },
    },
  );
};

function queryStringify(params) {
  if (typeof params !== "object") {
    // TODO: This should return empty string
    return null;
  }
  return Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
}
